import React from 'react';
import { Container } from 'reactstrap';

import { Footer, Header, Layout } from '@components';
import Submerged from '@components/BlogEntries/Submerged';

import './index.scss';

const SubmergedBlogPage = () => (
  <Layout>
    <nav aria-label="Main navigation" className="main-nav sticky-top" role="navigation">
      <Header displayCheatCodes={false} />
    </nav>
    <main role="main">
      <Container className="blog px-0" fluid>
        <Submerged includeSeo />
      </Container>
    </main>
    <footer role="contentinfo">
      <Footer />
    </footer>
  </Layout>
);

export default SubmergedBlogPage;
